/*
 * @description: 独立首页 
 * @Author: songyang 
 * @Date: 2020-06-10 10:31:05 
 * @LastModifiedBy: songyang 
 * @LastModifiedTime: 2020-06-10 10:31:05 
 */

import Loadable from 'react-loadable';
import React, { Component } from 'react';
const Handle = Loadable({
  loader: () => import('../../component/Index/Index'),
  loading: () => null,
})

export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Handle from='single'/>
  }
}
