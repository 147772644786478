/* 课程搜索wrap
 * @Author: guoguo;
 * @Date: 2019-03-26 09:47:00;
 * @Last Modified by:   guoguo;
 * @Last Modified time: 2019-03-26 09:47:00;
 */

import Loadable from 'react-loadable';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
const Handle = Loadable({
  loader: () => import('../../component/CourseClassify/SearchWrap'),
  loading: () => null,
})

class SearchWrap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Handle route={this.props.route} from="index" />
  }
}

export default withRouter(SearchWrap)
