/*
 * @description: 考题 
 * @Author: songyang 
 * @Date: 2020-06-01 10:32:27 
 * @LastModifiedBy: songyang 
 * @LastModifiedTime: 2020-06-01 10:32:27 
 */

import Loadable from 'react-loadable';
import React, { Component } from 'react';
const Handle = Loadable({
  loader: () => import('../../component/Exam/Exam'),
  loading: () => null,
})

export default class Exam extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Handle />
  }
}
