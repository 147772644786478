/*
 * @description: 独立我的
 * @Author: songyang 
 * @Date: 2020-06-11 15:58:09 
 * @LastModifiedBy: songyang 
 * @LastModifiedTime: 2020-06-11 15:58:09 
 */

import Loadable from 'react-loadable';
import React, { Component } from 'react';
const Handle = Loadable({
  loader: () => import('../../component/Mine/Index'),
  loading: () => null,
})

export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Handle from='single' />
  }
}
