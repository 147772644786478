/* @desc pdf课件预览
 * @Author: guoguo;
 * @Date: 2019-04-02 18:28:20;
 * @Last Modified by:   guoguo;
 * @Last Modified time: 2019-04-02 18:28:20;
 */

import Loadable from 'react-loadable';
import React, { Component } from 'react';
const Handle = Loadable({
  loader: () => import('../../component/CourseDetail/PdfView'),
  loading: () => null,
})

export default class PdfView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Handle />
  }
}
