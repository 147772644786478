import {
  TAB_PAGE, COURSE_TYPES, STORE_NOW_CATEGORY, CHANGE_SEARCH_VAL, IS_DOSEARCH
} from '../types/CourseClassify';

const initialState = {
  tabPage: 0,  // 分类tab的当前选中项
  courseTypes: [],
  nowCategory: null,
  searchVal: '',  // 搜索框的值
  isDoSearch: false,
}

export default function CourseClassify(state = initialState, action) {
  switch (action.type) {
    case TAB_PAGE:
      return {
        ...state,
        tabPage: action.tabPage,
      }
    case COURSE_TYPES:
      return {
        ...state,
        courseTypes: action.courseTypes,
      }
    case STORE_NOW_CATEGORY:
      return {
        ...state,
        nowCategory: action.nowCategory,
      }
    case CHANGE_SEARCH_VAL:
      return {
        ...state,
        searchVal: action.searchVal,
      }
    case IS_DOSEARCH:
      return {
        ...state,
        isDoSearch: action.isDoSearch,
      }
    default:
      return state;
  }
}
