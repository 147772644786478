/*
 * @description: 试卷预览 
 * @Author: songyang 
 * @Date: 2020-06-01 10:29:33 
 * @LastModifiedBy: songyang 
 * @LastModifiedTime: 2020-06-01 10:29:33 
 */

import Loadable from 'react-loadable';
import React, { Component } from 'react';
const Handle = Loadable({
  loader: () => import('../../component/Exam/Overview'),
  loading: () => null,
})

export default class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Handle />
  }
}
