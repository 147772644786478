/* @desc 培训班详情
 * @Author: guoguo;
 * @Date: 2019-04-03 16:59:54;
 * @Last Modified by:   guoguo;
 * @Last Modified time: 2019-04-03 16:59:54;
 */

import Loadable from 'react-loadable';
import React, { Component } from 'react';
const Handle = Loadable({
  loader: () => import('../../component/Mine/TrainingDetail'),
  loading: () => null,
})

export default class TrainingDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Handle />
  }
}