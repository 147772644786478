import {
  TABS_CHANGE
} from '../types/Task';

const initialState = {
  tabIndex: 0,  // 当前tab index
}

export default function LiveRadio(state = initialState, action) {
  switch (action.type) {
    case TABS_CHANGE:
      return {
        ...state,
        tabIndex: action.tabIndex,
      }
    default:
      return state;
  }
}
