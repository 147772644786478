
import {
  GROWTH_PLAN,
} from '../types/Index';

const initialState = {
  growthPlan: [],  // 成长计划
}

export default function IndexPage(state = initialState, action) {
  switch (action.type) {
    case GROWTH_PLAN:
      return {
        ...state,
        growthPlan: action.growthPlan,
      }
    default:
      return state;
  }
}
