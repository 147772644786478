/*
 * @description: 独立直播页
 * @Author: songyang 
 * @Date: 2020-06-10 10:32:18 
 * @LastModifiedBy: songyang 
 * @LastModifiedTime: 2020-06-10 10:32:18 
 */

import Loadable from 'react-loadable';
import React, { Component } from 'react';
const Handle = Loadable({
  loader: () => import('../../component/LiveRadio/Index'),
  loading: () => null,
})

export default class LiveRadio extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Handle from="single"/>
  }
}
