import {
  LIVE_RADIO_LIST
} from '../types/LiveRadio';

const initialState = {
  liveRadioList: [],  // 直播列表
}

export default function LiveRadio(state = initialState, action) {
  switch (action.type) {
    case LIVE_RADIO_LIST:
      return {
        ...state,
        liveRadioList: action.liveRadioList,
      }
    default:
      return state;
  }
}
