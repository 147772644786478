import {
  DONE_TASK, UNDONE_TASK, OVERDUE_TASK, STORE_TRAININGCLASS_DETAIL, TASK_TAB_CHANGE, COLLECT_TAB_CHANGE
} from '../types/Mine';

const initialState = {
  doneTask: [],  // 已完成任务
  undoneTask: [],  // 未完成任务
  overdueTask: [],  // 已逾期任务
  storeTrainingClassDetail: {},//暂存的培训班信息
  taskTabIndex: 0,  // 我的任务tab index
  collectTabIndex: 0,  // 我的收藏tab index
}

export default function LiveRadio(state = initialState, action) {
  switch (action.type) {
    case DONE_TASK:
      return {
        ...state,
        doneTask: action.doneTask,
      }
    case UNDONE_TASK:
      return {
        ...state,
        undoneTask: action.undoneTask,
      }
    case OVERDUE_TASK:
      return {
        ...state,
        overdueTask: action.overdueTask,
      }
    case STORE_TRAININGCLASS_DETAIL:
      return {
        ...state,
        storeTrainingClassDetail: action.storeTrainingClassDetail,
      }
    case TASK_TAB_CHANGE:
      return {
        ...state,
        taskTabIndex: action.taskTabIndex,
      }
    case COLLECT_TAB_CHANGE:
      return {
        ...state,
        collectTabIndex: action.collectTabIndex,
      }
    default:
      return state;
  }
}
