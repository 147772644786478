/*
 * @description: 培训 
 * @Author: songyang 
 * @Date: 2020-06-23 11:17:21 
 * @LastModifiedBy: songyang 
 * @LastModifiedTime: 2020-06-23 11:17:21 
 */

import Loadable from 'react-loadable';
import React, { Component } from 'react';
const Handle = Loadable({
  loader: () => import('../../component/Mine/Task'),
  loading: () => null,
})

export default class Task extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Handle from="single" />
  }
}
