/* @desc index路由
 * @Author: guoguo;
 * @Date: 2019-03-26 09:46:20;
 * @Last Modified by:   guoguo;
 * @Last Modified time: 2019-03-26 09:46:20;
 */

import App from './containers/App'
import Err403 from './views/Forbidden/403';
import Error from './views/Forbidden/Error';

import CourseTab from './views/TabBar/CourseTab';
import LiveTab from './views/TabBar/LiveTab';
import TaskTab from './views/TabBar/TaskTab';
import MineTab from './views/TabBar/MineTab';

import SingleIndex from './views/Single/Index';
import SingleLive from './views/Single/Live';
import SingleTask from './views/Single/Task';
import SingleMine from './views/Single/Mine';
import Archives from './views/Mine/Archives';
import StudyStar from './views/Mine/StudyStar';
import Task from './views/Mine/Task';
import Collection from './views/Mine/Collection';
import LiveMsg from './views/Mine/LiveMsg';
import TrainingMsg from './views/Mine/TrainingMsg'
import CommentMsg from './views/Mine/CommentMsg'

import CourseDetail from './views/Index/CourseDetail'
import CourseComment from './views/Index/CourseComment'
import CoursePdf from './views/Index/CoursePdf'
import CourseClassify from './views/Index/CourseClassify'
import ClassifyList from './views/Index/ClassifyList'
import MoreLive from './views/Index/MoreLive'
import GrowthPlan from './views/Index/GrowthPlan'
import TrainingDetail from './views/Index/TrainingDetail'

import SearchWrap from './views/Index/SearchWrap'
import SearchTips from './views/Index/SearchTips'
import SearchResult from './views/Index/SearchResult'

import ExamDetail from './views/Exam/Exam'
import ExamOverview from './views/Exam/Overview'

export default [
  {
    path: '/err',
    exact: true,
    component: Error,
  },
  {
    path: '/403',
    exact: true,
    component: Err403,
  },
  {
    component: App,
    routes: [
      /** tab主页面 */
      {
        path: '/',
        exact: true,
        title: '课程',
        component: CourseTab,
      },
      {
        path: '/course',
        title: '课程',
        component: CourseTab,
      },
      {
        path: '/live',
        title: '直播',
        component: LiveTab,
      },
      {
        path: '/task',
        title: '任务',
        component: TaskTab,
      },
      {
        path: '/mine',
        exact: true,
        title: '我的',
        component: MineTab,
      },
      /** tab主页面 */

      /** 插槽模块（目前主要提供不带底部tab的首页、直播、培训、我的） */
      {
        path: '/single/index',
        title: '首页',
        component: SingleIndex,
      },
      {
        path: '/single/live',
        title: '直播',
        component: SingleLive,
      },
      {
        path: '/single/task',
        title: '培训',
        component: SingleTask,
      },
      {
        path: '/single/mine',
        title: '我的',
        component: SingleMine,
      },
      /** 插槽模块 */

      /** 我的模块 */
      {
        path: '/mine/archives',
        title: '我的档案',
        component: Archives,
      },
      {
        path: '/mine/studyStar',
        title: '学习达人',
        component: StudyStar,
      },
      {
        path: '/mine/mineTask',
        title: '我的培训',
        component: Task,
      },
      {
        path: '/mine/collection',
        title: '我的收藏',
        component: Collection,
      },
      {
        path: '/mine/liveMsg',
        title: '直播提醒消息',
        component: LiveMsg,
      },
      {
        path: '/mine/trainingMsg/:trainingId',
        title: '培训班提醒消息',
        component: TrainingMsg,
      },
      {
        path: '/mine/commentMsg/:courseId',
        title: '评论提醒消息',
        component: CommentMsg,
      },
      /** 我的模块 */

      /** 考试模块 */
      {
        path: '/exam/overview/:questionPaperId/:chance',
        title: '试卷预览',
        component: ExamOverview,
      },
      {
        path: '/exam/detail/:examId/:questionPaperId/:startTime/:chance/:isError/:continued',
        title: '考试',
        component: ExamDetail,
      },
      /** 考试模块 */

      {
        path: '/courseDetail/:courseId',
        title: '课程详情',
        component: CourseDetail,
      },
      {
        path: '/comment/:courseId',
        title: '课程评论',
        component: CourseComment,
      },
      {
        path: '/pdfView/:courseId/:coursewareId/:pdfUrl/:pdfParma',
        title: '课件信息',
        component: CoursePdf,
      },
      {
        path: '/courseClassify',
        title: '课程分类',
        component: CourseClassify,
      },
      {
        path: '/classifyList/:categoryId',
        title: '分类课程列表',
        component: ClassifyList,
      },
      {
        path: '/moreLive',
        title: '更多直播',
        component: MoreLive,
      },
      {
        path: '/growthPlan/:taskId',
        title: '成长计划详情',
        component: GrowthPlan,
      },
      {
        path: '/training/:trainingId',
        title: '培训班详情',
        component: TrainingDetail,
      },
      {
        component: SearchWrap,
        routes: [
          {
            path: '/search',
            title: '课程搜索',
            component: SearchTips,
          },
          {
            path: '/searchResult',
            title: '搜索结果',
            component: SearchResult,
          },
        ],
      },
    ]
  },
]
