/* @desc 登录错误（移动端使用）
 * @Author: guoguo 
 * @Date: 2018-10-09 14:17:14 
 * @Last Modified by: songyang
 * @Last Modified time: 2020-06-24 16:51:16
 */
import React, { Component } from 'react';
import { Button, WhiteSpace, WingBlank } from 'antd-mobile'
import { parseUrlParam } from '../../utils/parseUrlParam';
const img500 = require('../../assets/img/500.png')

export default class Error extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  retry = () => {
    const paramObj = parseUrlParam(window.location.search.replace(/^\?/, ''));
    const prePath = paramObj.path;
    if (prePath) {
      window.location.replace(`${window.location.origin}${prePath}`);
    } else {
      window.location.replace('/');
    }
  }

  render() {
    const styles = {
      wrap: {
        position: 'absolute',
        width: '90%',
        maxWidth: 500,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center'
      },
      desc: {
        textAlign: 'center',
        lineHeight: '50px',
        fontSize: 16,
        fontWeight: 800,
      }
    }
    return (
      <div style={styles.wrap}>
        <div style={styles.desc}>加载错误，请稍后再试！</div>
        <img src={img500} alt="" style={{ width: '80%' }} />
        <WhiteSpace />
        <WingBlank>
          <Button type="primary" onClick={this.retry} inline size="small">重试</Button>
        </WingBlank>
      </div>
    )
  }
}
