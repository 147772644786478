import {
  SET_TITLE, USER_INFFO, LIVE_CONFIG, MOBILE_SELECT_TAB, IS_LOGIN
} from '../types/Base';

const initialState = {
  title: '',
  userInfo: {},
  liveConfig: {},  // 直播相关配置
  mobileTabKey: 'course',
  isLogin: false,
}

export default function Base(state = initialState, action) {
  switch (action.type) {
    case SET_TITLE:
      return {
        ...state,
        title: action.title,
      }
    case USER_INFFO:
      return {
        ...state,
        userInfo: action.userInfo,
      }
    case LIVE_CONFIG:
      return {
        ...state,
        liveConfig: action.liveConfig,
      }
    case MOBILE_SELECT_TAB:
      return {
        ...state,
        mobileTabKey: action.mobileTabKey,
      }
    case IS_LOGIN:
      return {
        ...state,
        isLogin: action.isLogin,
      }
    default:
      return state;
  }
}
