import { combineReducers } from 'redux';
import IndexPage from './IndexPage'
import Base from './Base';
import Course from './Course';
import CourseClassify from './CourseClassify';
import CourseDetails from './CourseDetails';
import LiveRadio from './LiveRadio';
import Mine from './Mine'
import Task from './Task'

const rootReducer = combineReducers({
  IndexPage,
  Base,
  Course,
  CourseClassify,
  CourseDetails,
  LiveRadio,
  Mine,
  Task,
});

export default rootReducer;
