import {
  COURSE_TYPES, HOT_COURSE, SALES_COURSE, MANAGE_COURSE, ALL_STUDY_COURSE
} from '../types/Course';

const initialState = {
  courseTypes: [],  // 课程分类树
  hotCourse: [],   // 热门课程
  salesCourse: [],  // 拼营销课程
  manageCourse: [],   // 会管理课程
  allStydyCourse: [],  // 同事们都在学的课程
}

export default function Course(state = initialState, action) {
  switch (action.type) {
    case COURSE_TYPES:
      return {
        ...state,
        courseTypes: action.courseTypes,
      }
    case HOT_COURSE:
      return {
        ...state,
        hotCourse: action.hotCourse,
      }
    case SALES_COURSE:
      return {
        ...state,
        salesCourse: action.salesCourse,
      }
    case MANAGE_COURSE:
      return {
        ...state,
        manageCourse: action.manageCourse,
      }
    case ALL_STUDY_COURSE:
      return {
        ...state,
        allStydyCourse: action.allStydyCourse,
      }
    default:
      return state;
  }
}
