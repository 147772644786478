/* @desc 手机端的403页面
 * @Author: guoguo 
 * @Date: 2018-10-09 14:17:14 
 * @Last Modified by: songyang
 * @Last Modified time: 2020-05-29 11:15:22
 */

import React, { Component } from 'react';
var img403 = require('../../assets/img/403.png')

export default class Mobile403 extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const styles = {
      wrap: {
        position: 'absolute',
        width: '90%',
        maxWidth: 500,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
      desc: {
        textAlign: 'center',
        lineHeight: '50px',
        fontSize: 16,
        fontWeight: 800,
      }
    }
    return (
      <div style={styles.wrap}>
        <div style={styles.desc}>权限不足，请联系管理员！</div>
        <img src={img403} alt="" style={{width: '100%'}} />
      </div>
    )
  }
}