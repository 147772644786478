/* @desc 课评论提醒消息
 * @Author: guoguo;
 * @Date: 2019-03-26 09:47:00;
 * @Last Modified by:   guoguo;
 * @Last Modified time: 2019-03-26 09:47:00;
 */

import Loadable from 'react-loadable';
import React, { Component } from 'react';
const Handle = Loadable({
  loader: () => import('../../component/CourseDetail/CourseComment'),
  loading: () => null,
})

export default class CommentMsg extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Handle from="msg" />
  }
}
