
import {
  SET_TITLE, USER_INFFO, LIVE_CONFIG, MOBILE_SELECT_TAB, IS_LOGIN
} from '../types/Base';
import axios from 'axios';
const instance = axios.create({
  baseURL: '',
});

/**
 * @description 获取用户
 */
export function getUserInfo() {
  return (dispatch) => {
    return instance.get('/service/userinfo')
      .then(res => {
        dispatch({
          type: USER_INFFO,
          userInfo: res.data.user,
        })
        return Promise.resolve(res.data.user)
      })
      .catch(err => {
        console.log(err)
        return Promise.reject(err)
      })
  }
}
// /**
//  * @description 获取用户
//  */
// export function getUserInfo() {
//   return (dispatch) => {
//     return axios.get('/api/user/info')
//       .then(res => {
//         dispatch({
//           type: USER_INFFO,
//           userInfo: res.data.data,
//         })
//         return Promise.resolve(res.data.data)
//       })
//       .catch(err => {
//         console.log(err)
//         return Promise.reject(err)
//       })
//   }
// }
/**
 * @description 更新当前的用户信息
 * @param {Object} data 用信息
 */
export function updateUserInfo(data) {
  return (dispatch, getState) => {
    dispatch({
      type: USER_INFFO,
      userInfo: Object.assign({}, getState().Base.userInfo, data),
    })
  }
}
/**
 * @description 设置当前路由的title
 */
export function setTitle(title) {
  return dispatch => {
    dispatch({
      type: SET_TITLE,
      title,
    })
  }
}
// 退出登录（清除session）
export function logout() {
  return () => {
    return instance.post('/service/logout')
      .then(res => {
        console.log(res)
        return Promise.resolve(res.data)
      })
      .catch(err => {
        console.log(err)
        return Promise.reject(err)
      })
  }
}
/**
 * @description 获取直播相关配置
 */
export function getLiveConfig() {
  return dispath => {
    return instance.get('/service/liveConfig') 
      .then(res => {
        console.log(res)
        dispath({
          type: LIVE_CONFIG,
          liveConfig: res.data,
        })
        return Promise.resolve(res.data)
      })
      .catch(err => {
        console.log(err)
        return Promise.reject(err)
      })
  }
}
/**
 * @description 获取协议相关配置
 */
export function getPactConfig() {
  return dispath => {
    return instance.get('/service/getPactConfig') 
      .then(res => {
        return Promise.resolve(res.data)
      })
      .catch(err => {
        console.log(err)
        return Promise.reject(err)
      })
  }
}

/**
 * @description 保存当前选中的tabkey
 * @param {String} tabKey 当先选中的tab
 */
export function mobileSelectTab(tabKey) {
  return dispatch => {
    dispatch({
      type: MOBILE_SELECT_TAB,
      mobileTabKey: tabKey,
    })
  }
}

/**
 * @description 根据验证码登录
 * @param {String} verCode 验证码
 */
export function LoginForCode(verCode) {
  return dispatch => {
    return instance.post('/service/verCode', { verCode })
      .then(res => {
        console.log("根据验证码登陆，并返回用户信息", res)
        return Promise.resolve(res.data.user);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }
}

/**
 * @description 标记当前app是否已经登陆
 */
export function isLogin(flag = true) {
  return dispatch => {
    dispatch({
      type: IS_LOGIN,
      isLogin: flag,
    })
  }
}
