/* @desc 首页tab
 * @Author: guoguo;
 * @Date: 2019-04-01 15:32:33;
 * @Last Modified by:   guoguo;
 * @Last Modified time: 2019-04-01 15:32:33;
 */

import 'react-app-polyfill/ie9';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';
import routes from './routes';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter, BrowserRouter } from 'react-router-dom';
import { renderRoutes } from "react-router-config"

const initialState = window.__INITIAL_STATE__;
const store = configureStore(initialState);

ReactDOM.render(
  <Provider store={store}>
    {
      process.env.NODE_ENV === 'development' ? (
        <HashRouter>
          {renderRoutes(routes)}
        </HashRouter>
      ) : (
          <BrowserRouter>
            {renderRoutes(routes)}
          </BrowserRouter>
        )
    }
  </Provider>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
