/**
 * url转化成object拼接样式
 * @param search 需要转化的参数
 */
export function parseUrlParam (search) {
  let string = search.split('&');
  let res = {};
  for (let i = 0; i < string.length; i ++){
      let str = string[i].split('=');
      if(str[0] !== ''){
          res[str[0]] = str[1];
      }
  }
  return res;
}

