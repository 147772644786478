import {
  COURSE_DETAILS,
} from '../types/CourseDetails';

const initialState = {
  courseDetails: null,  // 当前查看的课程详情信息
}

export default function CourseClassify(state = initialState, action) {
  switch (action.type) {
    case COURSE_DETAILS:
      return {
        ...state,
        courseDetails: action.courseDetails,
      }
    default:
      return state;
  }
}
