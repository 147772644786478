/*
 * @description: 任务（tab） 
 * @Author: songyang 
 * @Date: 2020-07-07 14:38:50 
 * @LastModifiedBy: songyang 
 * @LastModifiedTime: 2020-07-07 14:38:50 
 */

import Loadable from 'react-loadable';
import React, { Component } from 'react';
const Handle = Loadable({
  loader: () => import('./Index'),
  loading: () => null,
})

export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Handle tab='task' />
  }
}
